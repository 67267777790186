<template>
  <div flat class="overflow-hidden">
    <v-app-bar elevation="1" absolute color="white" dark scroll-target="#scrolling-techniques-8">
      <v-btn small icon color="secondary" @click="IrALista()">
        <v-icon>{{ icons.mdiArrowLeftThick }}</v-icon>
      </v-btn>

      <v-toolbar-title class="black--text">Presupuesto {{ datos.id <= 0 ? 'Nuevo' : datos.id }}</v-toolbar-title>

      <v-spacer></v-spacer>

      <v-btn small color="secondary" @click="limpiar()" class="me-3"> Limpiar </v-btn>
      <v-btn small color="primary" class="me-2" @click="guardar()"> Guardar </v-btn>
    </v-app-bar>
    <v-sheet id="scrolling-techniques-8" class="mx-0 px-0 overflow-y-auto" max-height="calc(100vh - 76px)">
      <div style="margin-top: 70px">
        <v-form ref="form" lazy-validation>
          <v-card-text class="d-flex">
            <v-row>
              <v-col md="6" cols="12">
                <v-card @click="abrirCliente()" min-height="170px">
                  <v-card-text>
                    <v-icon class="icon-tecla pr-2" color="black">{{ icons.mdiKeyboardF5 }}</v-icon>

                    <div v-if="datos.id_cliente > 0">
                      <p class="py-0 my-0">#:{{ datos.id_cliente }}</p>

                      <h3 class=" ">{{ datos.cliente.nombre }} {{ datos.cliente.apellido }}</h3>

                      <p class="py-0 my-0">{{ datos.cliente.documento }}</p>
                      <p class="py-0 my-0">direccion</p>
                    </div>
                    <div v-else>
                      <h2>Cliente</h2>
                      <p class="py-0 my-0">Debe seleccionar un cliente</p>
                    </div>
                  </v-card-text>
                </v-card>
              </v-col>
              <v-col md="6" cols="12">
                <DatePicker label="Fecha Vencimiento" outlined="true" dense="true" v-model="datos.fecha_vencimiento" />
                <v-textarea class="mt-4" outlined dense filled name="input-7-4" label="Descripcion"></v-textarea>
              </v-col>
            </v-row>
          </v-card-text>
        </v-form>
        <v-divider></v-divider>
        <v-card-text>
          <v-simple-table  >
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left" width="30%">Producto</th>
                  <th class="text-left" width="20%">Descripcion</th>
                  <th class="text-left" width="10%">Cantidad</th>
                  <th class="text-left" width="10%">Precio</th>
                  <th class="text-left" width="10%">Desc%</th>
                  <th class="text-left" width="10%">Iva%</th>
                  <th class="text-left" width="10%">Total</th>
                  <th class="text-left"><v-icon>{{ icons.mdiTune }}</v-icon></th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item, index) in datos.detalles" :key="index">
                  <td>  
                    <BuscarProducto  @change="changeProducto(item)" :dense="true" v-model="item.producto" :label="''" />
                  </td>
                  <td>
                    <v-textarea hide-details v-model="item.descripcion" dense name="input-7-4" rows="1"></v-textarea>
                  </td>
                  <td>
                    <v-text-field type="number" v-model="item.cantidad"></v-text-field>
                  </td>
                  <td>
                    <v-text-field v-model="item.precio"></v-text-field>
                  </td>
                  <td>
                    <v-text-field v-model="item.por_descuento"></v-text-field>
                  </td>
                  <td>
                    <v-text-field v-model="item.por_iva"></v-text-field>
                  </td>
                  <td>
                    <v-text-field readonly v-model="item.total"></v-text-field> 
                  </td>
                  <td>
                    <v-btn icon ><v-icon>{{ icons.mdiDelete }}</v-icon></v-btn>
                  </td>
                </tr>
              </tbody>
              <tfoot>
                <tr>
                  <td colspan="7">
                    <v-btn class="ma-2" outlined color="indigo" @click="agregarLinea()"> agregar linea </v-btn>

                  </td>
                </tr>
              </tfoot>
            </template>
          </v-simple-table>
        </v-card-text>

        <v-card-text>
          <v-row>
            <v-col md="8" cols="12"></v-col>
            <v-col  md="4"  cols="12">
              <v-simple-table dense>
                <template>
                  <tbody>
                    <tr  >
                      <td>Sub Total</td>
                      <td class="text-right">$ {{ funciones.formatoNumeric(datos.sub_total) }}</td>
                    </tr>
                    <tr>
                      <td>Descuento</td>
                      <td class="text-right">$ {{ funciones.formatoNumeric(datos.descuento) }}</td>
                    </tr>
                    <tr>
                      <td>Impuesto</td>
                      <td class="text-right">$ {{ funciones.formatoNumeric(datos.iva) }}</td>
                    </tr>

                    <tr>
                      <td>Total</td>
                      <td class="text-right">$ {{ funciones.formatoNumeric(datos.total) }}</td>
                    </tr>

                  </tbody>
                </template>
              </v-simple-table>
            </v-col>
          </v-row>
        </v-card-text>
      </div>
      <modal-cliente @GetClienteFull="GetClienteFull" ref="ModalClienteRef"></modal-cliente>
    </v-sheet>
  </div>
</template>
  <script>
import { onBeforeMount, ref } from '@vue/composition-api'
import store from '@/store'
import goTo from 'vuetify/lib/services/goto'
import draggable from 'vuedraggable'
import funciones from '@/funciones/funciones'
import BuscarCliente from '../../administracion/archivo/cliente/components/BuscarCliente.vue'
import DatePicker from '@/components/DatePicker.vue'
import BuscarProducto from '../../inventario/producto/componentes/BuscarProductoJson.vue'
import ModalCliente from '../../comanda/dashboard/componentes/ModalCliente.vue'
import {
  mdiPackageVariantClosed,
  mdiInformation,
  mdiDelete,
  mdiPencil,
  mdiArrowUpThick,
  mdiEqual,
  mdiArrowDownThick,
  mdiCached,
  mdiArrowLeftThick,
  mdiPlus,
  mdiTurkey,
  mdiseting,
  mdiTune
} from '@mdi/js'

import config from '@/api/config'
import router from '@/router'
export default {
  components: {
    draggable,
    BuscarCliente,
    DatePicker,
    ModalCliente,
    BuscarProducto,
  },

  setup(props, context) {
    const ModalClienteRef = ref()
    const datosRef = {
      id: 0,
      // id_estado: null,
      descripcion: '',
      id_cliente: null,
      fecha_vencimiento: null,
      sub_total: 0,
      iva: 0,
      descuento_detalle: 0,
      por_descuento: 0,
      descuento: 0,
      total: 0,
      detalles: [],
      cliente: {
        nombre: '',
        apellido: '',
        direccion: '',
        documento: '',
        telefono: '',
      },
    }
    const datosDetalleRef = {
      id: 0,
      producto:null,
      id_producto: null,
      descripcion: '',
      cantidad: '',
      precio: '',
      por_iva: '',
      iva: 0,
      por_descuento: '',
      descuento: 0,
      total: 0,
    }

    let contador = -10000
    const idcontador = () => {
      contador = contador + 1
      return contador
    }

    const changeProducto=(item)=>{
      item.id_producto = item.producto.id
      item.cantidad = 1
      item.precio = item.producto.precio_venta
      item.por_iva = item.producto.impuesto_tasa.valor
       
    }

    const agregarLinea = () => {
      datos.value.detalles.push(
        JSON.parse(
          JSON.stringify({
            ...datosDetalleRef,
            id: idcontador(),
          }),
        ),
      )
    }
    const reiniciarCliente = () => {
      datos.value.cliente = {
        nombre: '',
        apellido: '',
        direccion: '',
        documento: '',
        telefono: '',
      }
    }
    const GetClienteFull = dato => {
      alert('get')
      datos.value.id_cliente = dato.id
      datos.value.cliente = dato
    }
    const IrALista = () => {
      context.emit('IrALista', {})
    }
    const datos = ref(JSON.parse(JSON.stringify(datosRef)))
    const CargarEditar = async item => {
      console.log('CargarEditar', item)
    }

    const reglas = {
      requerido: [v => !!v || 'Es requerido'],
    }
    const abrirCliente = () => {
      ModalClienteRef.value.CambiarEstadoDialogo(true)
    }
    return {
      reglas,
      datos,
      CargarEditar,
      IrALista,
      GetClienteFull,
      reiniciarCliente,
      funciones,
      ModalClienteRef,
      abrirCliente,
      agregarLinea,
      changeProducto,
      icons: {
        mdiInformation,
        mdiDelete,
        mdiPencil,
        mdiArrowUpThick,
        mdiEqual,
        mdiArrowDownThick,
        mdiCached,
        mdiPackageVariantClosed,
        mdiArrowLeftThick,
        mdiPlus,
        mdiTune
      },
    }
  },
}
</script>