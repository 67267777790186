
<template>
  <div flat class="overflow-hidden">
    <v-app-bar elevation="1" absolute color="white" dark scroll-target="#scrolling-techniques-8">
      <v-toolbar-title class="black--text">Reporte </v-toolbar-title>
      <v-btn small color="primary" class="ml-2" @click="GetEditar({id:0})"> Nuevo </v-btn>
      <v-spacer></v-spacer>
      <v-btn small @click="limpiarFiltros()" :loading="cargandoDatos" class="secondary mr-2"> Limpiar </v-btn>
      <v-btn small @click="cargar()" :loading="cargandoDatos" class="primary"> Buscar </v-btn>
    </v-app-bar>
    
    <v-sheet id="scrolling-techniques-8" class="mx-0 px-0 overflow-y-auto" max-height="calc(100vh - 76px)">
       
      <div style="  margin-top: 70px;"> 
        <v-card-text>
          <v-simple-table>
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-center">ID</th>
                  <th class="text-left">Categoria</th>
                  <th class="text-left">Nombre</th>
                  <th class="text-left">Descripcion</th>
                  <th class="text-left">Activo</th>
                  <th class="text-left">Url</th> 
                  <th class="text-left">Tipo Reporte</th> 
                  <th class="text-left">Accion</th> 
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item, index) in Lista" :key="index">
                  <td>{{ item.id }}</td>
                  
                   
                </tr>
              </tbody>
              <tbody></tbody>
            </template>
          </v-simple-table>
           
        </v-card-text>
      </div>
    </v-sheet>
  </div>
</template>
    
  <script>
import CompraServices from '@/api/servicios/inventario/CompraServices'
import MaestroService from '@/api/servicios/MaestroService'
import ComandaService from '@/api/servicios/ComandaService'
import { onBeforeMount, ref, context, watch } from '@vue/composition-api'
import DatePicker from '@/components/DatePicker.vue'
import { acciones } from '@/modulos'
import {
  mdiMagnify,
  mdiDeleteOutline,
  mdiStoreEdit,
  mdiPencilOutline,
  mdiAccountCancel,
  mdiAccountEdit,
  mdiAccountCheck,
  mdiCheck,
  mdiCancel,
  mdiStoreEditOutline,
  mdiPencil,
  mdiCached,
  mdiEye,
  mdiContentSave,
  mdiChevronUp,
  mdiChevronDown,
} from '@mdi/js'
import config from '@/api/config'
import store from '@/store'
import funciones from '@/funciones/funciones'
import Vue from 'vue'
export default {
  components: {
    DatePicker,
  },

  setup(props, context) {
    const search = ref('')
    const Lista = ref([])
    const ListaLoad = ref(false)
    const cargandoDatos = ref(false)
    const Pagina = ref(1)
    const Limit = ref(5)
    const Paginas = ref(1)
    const TotalRow = ref(1)
    const show = ref(false)
    const ModalViewCompraRef = ref(null)
    const validarAcciones = accion => {
      const valor = store.state.user.accion_modulo.includes(accion)

      return valor
    }
    const filtroInit = {
      id_empleado: null,
      id_almacen: null,
      estado: null,
      id_puesto: null,
      fecha_factura: null,
      fecha_creacion: null,
      id_proveedor: null,
      valor: '',
      id_grupo_negocio: 1,
    }

    const limpiarFiltros = () => {
      filtro.value = JSON.parse(JSON.stringify(filtroInit))
      cargar()
    }

    const anulando = ref(false)

    const filtro = ref(JSON.parse(JSON.stringify(filtroInit)))

    onBeforeMount(() => {
      cargar()
    })
    watch(Pagina, () => {
      cargar()
    })
    const GetEditar = item => { 

      context.emit("GetEditar",item)
    }
    const cargarInicio = () => {
      Pagina.value = 1
      Limit.value = 5
      cargar()
    }
    const cargar = () => {
      try {
        cargandoDatos.value = true
        ComandaService.presupuestoListar()
        .then(response => {
          console.log(response)
          if (response.data.mensaje == 'BUSQUEDA_EXITOSA') {
            Lista.value = response.data.datos 
             
          }

        })
        .catch(error => {
          console.log(error)
        })
        .finally(() => {
          cargandoDatos.value = false
        })
      } catch (error) {
        cargandoDatos.value = false
      }
    }

    return {
      icons: {
        mdiMagnify,
        mdiDeleteOutline,
        mdiPencilOutline,
        mdiAccountCancel,
        mdiAccountEdit,
        mdiAccountCheck,
        mdiCheck,
        mdiCancel,
        mdiStoreEdit,
        mdiStoreEditOutline,
        mdiPencil,
        mdiCached,
        mdiEye,
        mdiContentSave,
        mdiChevronUp,
        mdiChevronDown,
      },
      search,
      headers: [
        { text: 'Id', value: 'id' },
        { text: 'Numero', value: 'numero_documento' },
        { text: 'Fechas', value: 'fecha_factura' },
        { text: 'Proveedor', value: 'proveedor.nombre' },
        { text: 'Empleado', value: 'empleado_json.nombre' },
        { text: 'Total', value: 'total' },
        { text: 'Estado', value: 'estado_json.descripcion' },
        { text: 'Accion', value: 'accion', sortable: false },
      ],
      Lista,
      ListaLoad,
      cargar,
      config,
      cargandoDatos,
      filtro,
      store,
      funciones,
      Pagina,
      Limit,
      Paginas,
      TotalRow,
      cargarInicio,
      GetEditar,
      validarAcciones,
      acciones,
      show,
      limpiarFiltros,
      
    }
  },
}
</script>
    
  <style lang="scss" scoped>
.table-kitchen-sink ::v-deep {
  .v-data-table-header {
    white-space: nowrap;
  }
}
</style>
    